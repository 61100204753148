import Navbar from "../Navbar/navbar"
import "../FAQs/faq.css"
import Footer from "../footer/footer"
import { useEffect } from "react"
import { Link } from "react-router-dom"

function Rules() {

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return <>
        <Navbar pg="rules"/>
        <div className="FAQ">
            <p className="dashboard" id="Faqs">Rules and Regulations</p>
            <div id="bullets" className="flex justify-between items-start gap-[0.34rem]"><span className="snoo">1.</span><p className="answer">The competition will be open to all the bonafide students of Engineering and Management Colleges with a minimum of 2 and a maximum of 3 authors per paper.</p></div>
            <hr className="faqLine" />
            <div id="bullets" className="flex justify-between items-start gap-[0.34rem]"><span className="snoo">2.</span> <p className="answer">Synopsis of maximum 1000 words to be sent by a laid down date. Based on the synopsis, an experts’ committee will select the papers for the final paper presentation.</p>
            </div>
            <hr className="faqLine" />
            <div id="bullets" className="flex justify-between items-start gap-[0.34rem]"><span className="snoo">3.</span><p className="answer">Participants need to prepare a power point presentation, which they will be presenting in front of the jury on the day of main event.</p>
            </div>
            <hr className="faqLine" />
            <div id="bullets" className="flex justify-between items-start gap-[0.34rem]"><span className="snoo">4.</span><p className="answer">For presentation, a time slot of 7 minutes + 3 minutes (for Q&A) will be given to each team.</p>
            </div>
            <hr className="faqLine" />
            <div id="bullets" className="flex justify-between items-start gap-[0.34rem]"><span className="snoo">5.</span><p className="answer">A soft copy of the technical paper and power point presentation is to be sent before the designated date.</p>
            </div>
            <hr className="faqLine" />
            <p className="dashboard" id="Faqs">Paper Format</p>
            
            <div id="bullets" className="flex justify-between items-start gap-[0.34rem]">
            {/* <span className="">1.</span> */}
    <p className="answer">This is the technical paper template designed to assist students in creating their academic papers. Inside, you will find comprehensive guidelines, rules, and regulations to ensure adherence to academic standards and submission requirements.</p>
</div>
<div id="bullets" className="flex mt-[-0.25rem] justify-between items-start gap-[0.34rem]">
            {/* <span className="snoo"></span> */}
    <Link target="_blank" to={"/template.docx"} className="answer" style={{textDecorationLine:"underline"}} download><span className="hover:text-[#FAC949] underline">TEMPLATE FOR TECHNICAL PAPER</span></Link>
</div>
<hr className="faqLine" />
{/* <div id="bullets" className="flex justify-between items-start gap-[0.34rem]">
    <span className="">2.</span>
    <p className="answer">Title should be written in 18 font size.</p>
</div>
<hr className="faqLine" />
<div id="bullets" className="flex justify-between items-start gap-[0.34rem]">
    <span className="">3.</span>
    <p className="answer">The paper can be a maximum of 10 pages with no page numbers and a margin of 1’’ all around to be left.</p>
</div>
<hr className="faqLine" />
<div id="bullets" className="flex justify-between items-start gap-[0.34rem]">
    <span className="">4.</span>
    <p className="answer">The first page to include Title, Name(s) of authors, their college name, telephone numbers as well as email addresses. This information to be included with extended abstract also.</p>
</div> */}
        </div>
        <Footer />
    </>

}

export default Rules
