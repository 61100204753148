

function UpdCard (props) {
    return <>
        <div className="updCard">
        <p className="updHead">{props.head}</p>
        <div className="updText">{props.text}
        {props.submit?<>Link for submitting the presentation: <a target="_blank" style={{textDecoration:"underline"}} href='https://forms.gle/jJNQY5naEqDsucFz6'>Submit Presentation</a></>:""}
        </div>
        </div>
    </>
}

export default UpdCard