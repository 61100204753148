import { useState } from "react";
import Navbar from "../Navbar/navbar";
import Footer from "../footer/footer";
import "./result.css";
import TableComp from "./table";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ResultThunk } from "../../Redux/resultSlice";
import { Spinner } from "react-bootstrap";
import im1 from "../Assets/CE_1st_Lighting Engineers.jpg"
import im2 from "../Assets/CE_2nd_Team Trifecta.jpg"
import im3 from "../Assets/CE_3rd_Team Byte Builders.jpg"
import im4 from "../Assets/CSIT_1st_First call fleet.jpg"
import im5 from "../Assets/CSIT_2nd_D-Generation Z.jpg"
import im6 from "../Assets/CSIT_3rd_Terminators.jpg"
import im7 from "../Assets/ECE_1st_Eunoia.jpg"
import im8 from "../Assets/ECE_2nd_Virtual Visionaries.jpg"
import im9 from "../Assets/ECE_3rd_Little Bits.jpg"
import im10 from "../Assets/En_1st_RoboRangers.jpg"
import im11 from "../Assets/EN_2nd_Curiosity Crusaders.jpg"
import im12 from "../Assets/EN_3rd_Rover Rangers.jpg"
import im13 from "../Assets/ME_1st_Team Resistance.jpg"
import im14 from "../Assets/ME_2nd_MechMages.jpg"
import im15 from "../Assets/MGMT_1st_Synergy Squad.jpg"
import im16 from "../Assets/MGMT_2nd_Planet Protectors.jpg"
import im17 from "../Assets/MGMT_3rd_LifeTech Leader.jpg"
import Slider from "react-slick";

const filterTeamsByDomain = (teams, domain) => {
  if(teams)
  return teams.filter(team => team.domain === domain);
}
const settings = {
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: true,
};

function Result() {
  const dispatch = useDispatch();
  const reducer = useSelector((s) => s.result);

  const team = {
    MS_team: "",
    CS_team: "",
    EN_team: "",
    EC_team: "",
    CE_team: "",
    ME_team: "",
  };
  const [selected, setSelected] = useState(team);
  const [wait, setWait] = useState(team);
  const [loading, setLoading] = useState(false);
const [teams,setTeams] = useState([]);
useEffect(() => {
  dispatch(ResultThunk());
}
, [dispatch]);
  useEffect(() => {
    setTeams(prev=>[...prev,...[{
      team_id: "Unstop",
      name: "AG_OG",
      domain: "Management Science",
      topic: "Advance Aerial Survillance Integrating AI and Drone Tech for Enchance Women Security",
      leader_name: "Gauransh Goel",
    },{
      team_id: "Unstop",
      name: "Akgecianxisro",
      domain: "Management Science",
      topic: "Predicting Infant Mortality Rates in India: A Random Forest Approach",
      leader_name: "Shreeya Prasad",
    },
    {
      team_id: "Unstop",
      name: "Team Trifecta",
      domain: "Civil Engineering",
      topic: "Intelligent Transport system",
      leader_name: "Ayush Pathak",
    }
  ]])
  }, []);
  useEffect(() => {
    if(teams.length<4)
    setTeams(prev=>[...prev,...reducer.selectedTeam]);
  }, [reducer.selectedTeam]);

  const CS_team = filterTeamsByDomain(teams, "Computer Science and Information Technology");
  const ME_team = filterTeamsByDomain(teams, "Mechanical Engineering");
  const MS_team = filterTeamsByDomain(teams, "Management Science");
  const EN_team = filterTeamsByDomain(teams,"Electrical and Electronics Engineering");
  const EC_team = filterTeamsByDomain(teams,"Electronics and Communication Engineering");
  const CE_team = filterTeamsByDomain(teams,"Civil Engineering");
  console.log(EN_team);
  // useEffect(() => {
  //   if (
  //     reducer.selectedTeam != "" &&
  //     reducer.selectedTeam != null &&
  //     reducer.selectedTeam != undefined
  //   ) {
  //     setSelected({
  //       ...selected,
  //       MS_team: reducer.selectedTeam.msg.MS_team,
  //       CS_team: reducer.selectedTeam.msg.CS_team,
  //       EN_team: reducer.selectedTeam.msg.EN_team,
  //       EC_team: reducer.selectedTeam.msg.EC_team,
  //       CE_team: reducer.selectedTeam.msg.CE_team,
  //       ME_team: reducer.selectedTeam.msg.ME_team,
  //     });
  //   }
  //   // if (
  //   //   reducer.waitlistedTeam != "" &&
  //   //   reducer.waitlistedTeam != null &&
  //   //   reducer.waitlistedTeam != undefined
  //   // ) {
  //   //   setWait({
  //   //     ...wait,
  //   //     MS_team: reducer.waitlistedTeam.MS_team,
  //   //     CS_team: reducer.waitlistedTeam.CS_team,
  //   //     EC_team: reducer.waitlistedTeam.EC_team,
  //   //   });
  //   // }
  // }, [reducer]);

  const [timer, setTimer] = useState(10);
  useEffect(() => {
    if (reducer.loading) {
      const time =
        timer > 0 &&
        setInterval(() => {
          setTimer(timer - 1);
        }, 1000);
      return () => clearInterval(time);
    }
  }, [timer, reducer.loading]);

  useEffect(() => {
    if (timer > 0 && reducer.loading) {
      setLoading(true);
      document.body.style.opacity = 0.5;
    } else {
      setLoading(false);
      document.body.style.opacity = 1;
    }
  }, [reducer.loading, timer]);

  return (
    <>
      <Navbar pg="res"/>
      <div className="sm:px-[2rem]" id="RESULT">
        <div id="resultHead">Results - SCROLLS 2024</div>
        <TableComp
          name="Computer Science and Information Technology"
          data={CS_team}
          wait={wait.CS_team}
          bool="true"
          team1={"FirstCall Fleet"}
          team2={"D-Generation Z"}
          team3={"Terminators"}
        />
        <TableComp
          name="Civil Engineering"
          data={CE_team}
          wait={wait.CE_team}
          bool="false"
          team1={"Lighting Engineers"}
          team2={"Team Trifecta"}
          team3={"Team ByteBuilders"}
        />
        <TableComp
          name="Electrical and Electronics Engineering"
          data={EN_team}
          wait={wait.EN_team}
          bool="false"
          team1={"Robo Rangers"}
          team2={"Curiosity Crusaders"}
          team3={"Rover Rangers"}
        />
        <TableComp
          name="Electronics and Communication Engineering"
          data={EC_team}
          wait={wait.EC_team}
          bool="true"
          team1={"Eunoia"}
          team2={"Virtual Visionaries"}
          team3={"Little Bits"}
        />
        <TableComp
          name="Mechanical Engineering"
          data={ME_team}
          wait={wait.ME_team}
          bool="false"
          team1={"Team Resistance"}
          team2={"Team Mechmages"}
          team3={"Smart Researchers"}
        />
        <TableComp
          name="Management Sciences"
          data={MS_team}
          wait={wait.MS_team}
          bool="true"
          team1={"Synergy Squad"}
          team2={"Planet Protectors"}
          team3={"LifeTech Leader"}
        />
      </div>
      <div style={{width:'100%',marginTop:'0rem',marginBottom:"3rem"}}>
          <Slider {...settings}>
        <div className="">
          <img className="sliderItem" src={im1} />
        </div>
        <div className="">
          <img className="sliderItem" src={im2} />
        </div>
        <div className="">
          <img className="sliderItem" src={im3} />
        </div>
         <div className="">
          <img className="sliderItem" src={im4} />
        </div>
        <div className="">
          <img className="sliderItem" src={im5} />
        </div>
        <div className="">
          <img className="sliderItem" src={im6} />
        </div>
        <div className="">
          <img className="sliderItem" src={im7} />
        </div>
        <div className="">
          <img className="sliderItem" src={im8} />
        </div>
        <div className="">
          <img className="sliderItem" src={im9} />
        </div>
        <div className="">
          <img className="sliderItem" src={im10} />
        </div>
        <div className="">
          <img className="sliderItem" src={im11} />
        </div><div className="">
          <img className="sliderItem" src={im12} />
        </div><div className="">
          <img className="sliderItem" src={im13} />
        </div><div className="">
          <img className="sliderItem" src={im14} />
        </div><div className="">
          <img className="sliderItem" src={im15} />
        </div><div className="">
          <img className="sliderItem" src={im16} />
        </div>
        <div className="">
          <img className="sliderItem" src={im17} />
        </div>
      </Slider>
      </div>
      <Footer />

      {loading ? (
        <Spinner animation="border" variant="dark" id="loadSpinner" />
      ) : null}
    </>
  );
}

export default Result;
